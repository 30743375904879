<template>
  <v-card>
    <v-card-text>
      <div class="radio">
        <b>{{ $t('system.was_conversation_with_manager') }}</b>
        <v-radio-group
          class="conversation-radio mt-0"
          :value="servicesSettlement"
          readonly
          dense
          row
        >
          <v-radio
            :value="true"
            color="success"
            :label="$t('system.yes')"
          />
          <v-radio
            :value="false"
            color="error"
            :label="$t('system.no')"
          />
        </v-radio-group>
      </div>
      <v-data-table
        :headers="headers.map(header => ({...header, text: $t(header.text)}))"
        :items="positions"
        :loading="loading"
      />
    </v-card-text>
  </v-card>
</template>

<script>
import axios from 'axios'
import notification from "@/notification/notify";

export default {
  name: 'ServicesStep',
  props: {
    visitId: {
      type: [Number, String],
      required: true
    }
  },
  data: () => ({
    headers: [
      {
        text: 'system.name',
        sortable: true,
        value: 'product_name',
      },
      {
        text: 'system.quantity',
        sortable: true,
        value: 'quantity',
      }
    ],
    loading: false,
    positions: [],
    servicesSettlement: null
  }),
  beforeMount() {
    this.fetchData()
  },
  methods: {
    async fetchData() {
      try {
        this.loading = true
        const {data} = await axios.get(`sk_visits/${this.visitId}/step/services`)
        this.positions = data.positions
        this.servicesSettlement = !!data.settlement_of_services
      } catch (e) {
        notification.notify(
          this.$t('notify.error'),
          this.$t('system.can_not_load'),
          'error');
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style lang="scss">
.radio {
  label {
    margin-bottom: 0 !important;
  }
}
</style>
